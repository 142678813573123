import React from "react";
import { useSiteConfig } from "../GraphQl/useSiteConfig";
import Layout from "../components/layout";
import { PageMeta } from "../components/pageMeta";
import { PortableTextBlock } from "../components/sanity/portableTextBlock";

const PrivacyPolicy = () => {
  const { privacyPolicy } = useSiteConfig();

  return (
    <Layout>
      <PageMeta {...privacyPolicy.meta} />
      <div className="py-32 lg:py-60 grid grid-cols-14">
        <div className="col-start-2 lg:col-start-5 col-end-14 lg:col-end-11 text-center">
          {privacyPolicy.heading && (
            <h1 className="leading-12 lg:leading-16 blockH1 text-teal">
              {privacyPolicy.heading}
            </h1>
          )}
        </div>

        {privacyPolicy.content && (
          <div className="mt-32 col-start-2 col-end-14 lg:col-start-4 lg:col-end-12 text-left">
            <PortableTextBlock text={privacyPolicy.content} />
          </div>
        )}
      </div>
    </Layout>
  );
};

export default PrivacyPolicy;
